import React from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import SearchSuggestion from "../../../../SubComponents/SearchSuggestion/SearchSuggestion";
import Dropdown from "../../../../SubComponents/Dropdown/Dropdown";
import ReactLoading from "react-loading";
import "./topicReportSearch.css";
import { topicReportSubmit } from "../../TopicReportFunction/topicReportSubmit";

function TopicReportSearch({
  state,
  formData,
  handleChange,
  submitLoading,
  noData,
  achieverSelected,
  searchSuggestion,
  handleUlClose,
  handleAchieverClick,
  handleOnFocus,
  setSubmitLoading,
  setNoData,
  setNumberOfTopicReports,
  setTopicReportList,
  limit,
  offSet,
  setStartUpLoad,
  createDownloadInfo,
}) {
  const difficultyLevel = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  return (
    <div>
      <form>
        <div className="topicReportContainer">
          <h1 className="topicReportH3">
            <ArrowForwardIosIcon className="topicReportArrow" />
            Topic Report
          </h1>
        </div>
        <div>
          <div className="topicReportSearchContainer">
            <h4 className="topicReportHeading">Search Topic Report</h4>
            <div className="questionViewdropdownDiv">
              <label className="topicReportLabel">Created By</label>
              <SearchSuggestion
                achieverSelected={achieverSelected}
                // disabled={editValue}
                handleChange={handleChange}
                searchSuggestion={searchSuggestion}
                dropDownData={state.achieverNameList}
                handleUlClose={handleUlClose}
                handleUlClick={handleAchieverClick}
                handleOnFocus={handleOnFocus}
                name="user_name"
                type="createdBy"
              />
            </div>
            <Dropdown
              label="Medium"
              options={state.medium}
              name="medium_id"
              displayName="medium_name"
              idName="medium_id"
              value={formData.medium_id}
              onChange={handleChange}
              req={false}
            />
            <Dropdown
              label="Questions Type"
              options={state.questionsType}
              displayName="display_name"
              name="question_type_id"
              idName="id"
              value={formData.question_type_id}
              onChange={handleChange}
              req={false}
            >
              {" "}
            </Dropdown>
            <Dropdown
              label="Board"
              options={state.board}
              name="board_id"
              displayName="board_display_name"
              idName="board_id"
              value={formData.board_id}
              onChange={handleChange}
              req={false}
            />
            <Dropdown
              label="Course"
              options={state.course}
              name="course_id"
              displayName="display_name"
              idName="course_id"
              value={formData.course_id}
              onChange={handleChange}
              req={false}
            />
            <Dropdown
              label="Subject"
              options={state.subject}
              name="subject_id"
              displayName="display_name"
              idName="subject_id"
              value={formData.subject_id}
              onChange={handleChange}
              req={false}
            />
            <div className="topRepCheckDiv">
              <div className="topRepdropdown">
                <label className="topRepDropdownLabel">Sub-Subject</label>
                <input
                  className="topicReportCheckbox"
                  onChange={(e) => handleChange(e, "sub_subject")}
                  type="checkbox"
                  checked={formData.sub_subject}
                ></input>
              </div>
            </div>
            <Dropdown
              label="Topic"
              options={state.topic}
              name="topic_id"
              displayName="display_name"
              idName="topic_id"
              value={formData.topic_id}
              onChange={handleChange}
              req={false}
              displayEngName={"display_name_eng"}
            />
            <Dropdown
              label="Taxonomy"
              options={state.taxonomies}
              displayName="display_name"
              name="taxonomy_id"
              idName="id"
              onChange={handleChange}
              value={formData.taxonomy_id}
              req={false}
            />
            <Dropdown
              label="Objective"
              options={state.objectives}
              displayName="objective_code"
              name="objective_id"
              idName="objective_id"
              onChange={handleChange}
              value={formData.objective_id}
              req={false}
            />
            <Dropdown
              label="Difficulty Level"
              options={difficultyLevel}
              name="difficulty_level"
              displayName=""
              value={formData.difficulty_level}
              onChange={handleChange}
              req={false}
            />
            <div className="topicReportDropdownDiv">
              <label className="topicReportLabel">From Date</label>
              <input
                className="topicReportSelect"
                type="date"
                value={formData.from_date}
                onChange={(e) => handleChange(e, "from_date")}
              ></input>
            </div>
            <div className="topicReportDropdownDiv">
              <label className="topicReportLabel">To Date</label>
              <input
                className="topicReportSelect"
                type="date"
                value={formData.to_date}
                onChange={(e) => handleChange(e, "to_date")}
              ></input>
            </div>
            <div className="topicReportDropdownDiv">
              <div className="topicReportLabel">Curated</div>
              <div className="topicReportRadio">
                <div>
                  <input
                    type="radio"
                    id="curated"
                    name="fav_language"
                    value={"curated"}
                    onChange={(e) => {
                      console.log(e, e.target.value, e.target.checked);
                      handleChange(e, "is_curated");
                    }}
                  />
                  <label htmlFor="curated">Curated</label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="non_curated"
                    name="fav_language"
                    value={"non_curated"}
                    onChange={(e) => {
                      console.log(e, e.target.value, e.target.checked);
                      handleChange(e, "is_curated");
                    }}
                  />
                  <label htmlFor="non_curated">Non Curated</label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="all"
                    name="fav_language"
                    value={"all"}
                    defaultChecked={formData?.is_curated === "all"}
                    onChange={(e) => {
                      console.log(e, e.target.value, e.target.checked);
                      handleChange(e, "is_curated");
                    }}
                  />
                  <label htmlFor="all">All</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="topicReportSearchButtonContainer">
          <button
            className="topicReportSearchBtn"
            id="searchQuestions"
            type="button"
            onClick={() => {
              topicReportSubmit(
                setSubmitLoading,
                setNoData,
                formData,
                setNumberOfTopicReports,
                setTopicReportList,
                limit,
                0,
                setStartUpLoad
              );
              createDownloadInfo(formData);
            }}
          >
            Search
          </button>
          {submitLoading ? (
            <div className="topicReportloadingDiv">
              <ReactLoading
                type="spinningBubbles"
                color="#68dff0"
                className="topicRepReactLoadingIcon"
              />
              <h5 className="loadingText">Loading</h5>
            </div>
          ) : null}
          {noData ? (
            <div className="topicReportloadingDiv">
              <h5 className="topicRepLoadingTextData">Data is not available</h5>
            </div>
          ) : null}
        </div>
      </form>
    </div>
  );
}

export default TopicReportSearch;
